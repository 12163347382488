<template>
   <div>子账户套餐</div>
</template>

<script>
export default {
  name: "subAccountList"
}
</script>

<style scoped>

</style>